/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@-ms-viewport {
  width: device-width; }
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }

  table.visible-xs {
    display: table; }

  tr.visible-xs {
    display: table-row !important; }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }

  table.visible-sm {
    display: table; }

  tr.visible-sm {
    display: table-row !important; }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }

  table.visible-md {
    display: table; }

  tr.visible-md {
    display: table-row !important; }

  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }

  table.visible-lg {
    display: table; }

  tr.visible-lg {
    display: table-row !important; }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }
.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }

  table.visible-print {
    display: table; }

  tr.visible-print {
    display: table-row !important; }

  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }
.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }
.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }
.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }
@media print {
  .hidden-print {
    display: none !important; } }
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }
@media (min-width: 992px) {
  .container {
    width: 970px; } }
@media (min-width: 1200px) {
  .container {
    width: 1170px; } }
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.33333333%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.33333333%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.33333333%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0%; }

@media (min-width: 768px) {
  .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }

  .col-sm-12 {
    width: 100%; }

  .col-sm-11 {
    width: 91.66666667%; }

  .col-sm-10 {
    width: 83.33333333%; }

  .col-sm-9 {
    width: 75%; }

  .col-sm-8 {
    width: 66.66666667%; }

  .col-sm-7 {
    width: 58.33333333%; }

  .col-sm-6 {
    width: 50%; }

  .col-sm-5 {
    width: 41.66666667%; }

  .col-sm-4 {
    width: 33.33333333%; }

  .col-sm-3 {
    width: 25%; }

  .col-sm-2 {
    width: 16.66666667%; }

  .col-sm-1 {
    width: 8.33333333%; }

  .col-sm-pull-12 {
    right: 100%; }

  .col-sm-pull-11 {
    right: 91.66666667%; }

  .col-sm-pull-10 {
    right: 83.33333333%; }

  .col-sm-pull-9 {
    right: 75%; }

  .col-sm-pull-8 {
    right: 66.66666667%; }

  .col-sm-pull-7 {
    right: 58.33333333%; }

  .col-sm-pull-6 {
    right: 50%; }

  .col-sm-pull-5 {
    right: 41.66666667%; }

  .col-sm-pull-4 {
    right: 33.33333333%; }

  .col-sm-pull-3 {
    right: 25%; }

  .col-sm-pull-2 {
    right: 16.66666667%; }

  .col-sm-pull-1 {
    right: 8.33333333%; }

  .col-sm-pull-0 {
    right: auto; }

  .col-sm-push-12 {
    left: 100%; }

  .col-sm-push-11 {
    left: 91.66666667%; }

  .col-sm-push-10 {
    left: 83.33333333%; }

  .col-sm-push-9 {
    left: 75%; }

  .col-sm-push-8 {
    left: 66.66666667%; }

  .col-sm-push-7 {
    left: 58.33333333%; }

  .col-sm-push-6 {
    left: 50%; }

  .col-sm-push-5 {
    left: 41.66666667%; }

  .col-sm-push-4 {
    left: 33.33333333%; }

  .col-sm-push-3 {
    left: 25%; }

  .col-sm-push-2 {
    left: 16.66666667%; }

  .col-sm-push-1 {
    left: 8.33333333%; }

  .col-sm-push-0 {
    left: auto; }

  .col-sm-offset-12 {
    margin-left: 100%; }

  .col-sm-offset-11 {
    margin-left: 91.66666667%; }

  .col-sm-offset-10 {
    margin-left: 83.33333333%; }

  .col-sm-offset-9 {
    margin-left: 75%; }

  .col-sm-offset-8 {
    margin-left: 66.66666667%; }

  .col-sm-offset-7 {
    margin-left: 58.33333333%; }

  .col-sm-offset-6 {
    margin-left: 50%; }

  .col-sm-offset-5 {
    margin-left: 41.66666667%; }

  .col-sm-offset-4 {
    margin-left: 33.33333333%; }

  .col-sm-offset-3 {
    margin-left: 25%; }

  .col-sm-offset-2 {
    margin-left: 16.66666667%; }

  .col-sm-offset-1 {
    margin-left: 8.33333333%; }

  .col-sm-offset-0 {
    margin-left: 0%; } }
@media (min-width: 992px) {
  .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }

  .col-md-12 {
    width: 100%; }

  .col-md-11 {
    width: 91.66666667%; }

  .col-md-10 {
    width: 83.33333333%; }

  .col-md-9 {
    width: 75%; }

  .col-md-8 {
    width: 66.66666667%; }

  .col-md-7 {
    width: 58.33333333%; }

  .col-md-6 {
    width: 50%; }

  .col-md-5 {
    width: 41.66666667%; }

  .col-md-4 {
    width: 33.33333333%; }

  .col-md-3 {
    width: 25%; }

  .col-md-2 {
    width: 16.66666667%; }

  .col-md-1 {
    width: 8.33333333%; }

  .col-md-pull-12 {
    right: 100%; }

  .col-md-pull-11 {
    right: 91.66666667%; }

  .col-md-pull-10 {
    right: 83.33333333%; }

  .col-md-pull-9 {
    right: 75%; }

  .col-md-pull-8 {
    right: 66.66666667%; }

  .col-md-pull-7 {
    right: 58.33333333%; }

  .col-md-pull-6 {
    right: 50%; }

  .col-md-pull-5 {
    right: 41.66666667%; }

  .col-md-pull-4 {
    right: 33.33333333%; }

  .col-md-pull-3 {
    right: 25%; }

  .col-md-pull-2 {
    right: 16.66666667%; }

  .col-md-pull-1 {
    right: 8.33333333%; }

  .col-md-pull-0 {
    right: auto; }

  .col-md-push-12 {
    left: 100%; }

  .col-md-push-11 {
    left: 91.66666667%; }

  .col-md-push-10 {
    left: 83.33333333%; }

  .col-md-push-9 {
    left: 75%; }

  .col-md-push-8 {
    left: 66.66666667%; }

  .col-md-push-7 {
    left: 58.33333333%; }

  .col-md-push-6 {
    left: 50%; }

  .col-md-push-5 {
    left: 41.66666667%; }

  .col-md-push-4 {
    left: 33.33333333%; }

  .col-md-push-3 {
    left: 25%; }

  .col-md-push-2 {
    left: 16.66666667%; }

  .col-md-push-1 {
    left: 8.33333333%; }

  .col-md-push-0 {
    left: auto; }

  .col-md-offset-12 {
    margin-left: 100%; }

  .col-md-offset-11 {
    margin-left: 91.66666667%; }

  .col-md-offset-10 {
    margin-left: 83.33333333%; }

  .col-md-offset-9 {
    margin-left: 75%; }

  .col-md-offset-8 {
    margin-left: 66.66666667%; }

  .col-md-offset-7 {
    margin-left: 58.33333333%; }

  .col-md-offset-6 {
    margin-left: 50%; }

  .col-md-offset-5 {
    margin-left: 41.66666667%; }

  .col-md-offset-4 {
    margin-left: 33.33333333%; }

  .col-md-offset-3 {
    margin-left: 25%; }

  .col-md-offset-2 {
    margin-left: 16.66666667%; }

  .col-md-offset-1 {
    margin-left: 8.33333333%; }

  .col-md-offset-0 {
    margin-left: 0%; } }
@media (min-width: 1200px) {
  .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }

  .col-lg-12 {
    width: 100%; }

  .col-lg-11 {
    width: 91.66666667%; }

  .col-lg-10 {
    width: 83.33333333%; }

  .col-lg-9 {
    width: 75%; }

  .col-lg-8 {
    width: 66.66666667%; }

  .col-lg-7 {
    width: 58.33333333%; }

  .col-lg-6 {
    width: 50%; }

  .col-lg-5 {
    width: 41.66666667%; }

  .col-lg-4 {
    width: 33.33333333%; }

  .col-lg-3 {
    width: 25%; }

  .col-lg-2 {
    width: 16.66666667%; }

  .col-lg-1 {
    width: 8.33333333%; }

  .col-lg-pull-12 {
    right: 100%; }

  .col-lg-pull-11 {
    right: 91.66666667%; }

  .col-lg-pull-10 {
    right: 83.33333333%; }

  .col-lg-pull-9 {
    right: 75%; }

  .col-lg-pull-8 {
    right: 66.66666667%; }

  .col-lg-pull-7 {
    right: 58.33333333%; }

  .col-lg-pull-6 {
    right: 50%; }

  .col-lg-pull-5 {
    right: 41.66666667%; }

  .col-lg-pull-4 {
    right: 33.33333333%; }

  .col-lg-pull-3 {
    right: 25%; }

  .col-lg-pull-2 {
    right: 16.66666667%; }

  .col-lg-pull-1 {
    right: 8.33333333%; }

  .col-lg-pull-0 {
    right: auto; }

  .col-lg-push-12 {
    left: 100%; }

  .col-lg-push-11 {
    left: 91.66666667%; }

  .col-lg-push-10 {
    left: 83.33333333%; }

  .col-lg-push-9 {
    left: 75%; }

  .col-lg-push-8 {
    left: 66.66666667%; }

  .col-lg-push-7 {
    left: 58.33333333%; }

  .col-lg-push-6 {
    left: 50%; }

  .col-lg-push-5 {
    left: 41.66666667%; }

  .col-lg-push-4 {
    left: 33.33333333%; }

  .col-lg-push-3 {
    left: 25%; }

  .col-lg-push-2 {
    left: 16.66666667%; }

  .col-lg-push-1 {
    left: 8.33333333%; }

  .col-lg-push-0 {
    left: auto; }

  .col-lg-offset-12 {
    margin-left: 100%; }

  .col-lg-offset-11 {
    margin-left: 91.66666667%; }

  .col-lg-offset-10 {
    margin-left: 83.33333333%; }

  .col-lg-offset-9 {
    margin-left: 75%; }

  .col-lg-offset-8 {
    margin-left: 66.66666667%; }

  .col-lg-offset-7 {
    margin-left: 58.33333333%; }

  .col-lg-offset-6 {
    margin-left: 50%; }

  .col-lg-offset-5 {
    margin-left: 41.66666667%; }

  .col-lg-offset-4 {
    margin-left: 33.33333333%; }

  .col-lg-offset-3 {
    margin-left: 25%; }

  .col-lg-offset-2 {
    margin-left: 16.66666667%; }

  .col-lg-offset-1 {
    margin-left: 8.33333333%; }

  .col-lg-offset-0 {
    margin-left: 0%; } }
.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table; }

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@media (min-width: 990px) {
  .is-header {
    position: fixed; } }
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'open_sansregular', 'sans-serif'; }

header, article, nav {
  background: white;
  padding: 15px;
  margin: 15px 0 15px 0; }

p {
  margin: 7px 0 7px 0; }

h1 {
  font-size: 1.875rem;
  color: #ac0000;
  font-family: 'montserratbold', sans-serif; }

nav {
  font-family: 'open_sansregular', 'sans-serif'; }

nav > h2 {
  margin: 0 0 30px 0;
  font-size: 1.25rem;
  text-transform: uppercase;
  text-align: left;
  font-family: 'open_sansregular', 'sans-serif';
  font-weight: normal; }

nav > ul {
  padding: 0;
  list-style: none;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.875em;
  line-height: 1.2rem; }

nav img {
  height: 50px;
  width: 50px !important; }

nav a:hover {
  color: #ac0000; }

nav > p {
  font-size: 0.81rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

nav a {
  color: black;
  text-decoration: none; }

a {
  color: #ac0000;
  text-decoration: none; }

ul {
  font-size: 0.82rem;
  line-height: 1.375rem; }

li > button {
  width: 10px;
  height: 12px;
  border-radius: 100%;
  float: left;
  background-color: #ac0000;
  border: 0;
  margin-top: 5px; }

article {
  vertical-align: top;
  margin: 10px 10px 30px 10px; }

article a h2, article h2 {
  margin: 0 0 15px 0;
  font-size: 14px;
  font-family: 'Open_sansbold', sans-serif;
  text-transform: uppercase;
  color: black; }

article a h2:hover {
  color: #ac0000; }

article > p {
  font-size: 0.82rem;
  line-height: 1.375rem; }

.meta {
  line-height: 1.375rem;
  display: inline; }
  .meta * {
    font-size: 0.70rem; }
  .meta li ul, .meta li ul li {
    display: inline; }
  .meta li ul li:after {
    content: "-"; }
  .meta li ul li:last-child:after {
    content: ""; }

article > blockquote {
  padding-left: 20px;
  margin: 15px 0 15px 0;
  line-height: 1.375rem;
  font-size: 0.875rem; }

blockquote > ul > li {
  list-style: none; }

figure {
  margin: 15px 0 15px 0; }

figure > img {
  cursor: pointer;
  width: 100%; }

a img {
  margin: 15px 0 15px 0;
  width: 100%; }

footer {
  display: block;
  font-size: 0.62rem;
  text-align: center; }

.pagination {
  margin-bottom: 1.5rem; }

.pagination-item {
  position: relative;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: #efeeed;
  color: #ac0000;
  border-bottom: 0; }

.pagination-item:hover, .pagination-item:focus {
  background: #ac0000;
  color: white; }

.pagination-item:active {
  background: #ac0000; }

.pagination-item.left {
  float: left; }

.pagination-item.right {
  float: right; }

.pagination-item svg {
  position: relative;
  top: 50%;
  display: block;
  margin: -6px auto 0;
  width: 24px;
  height: 12px; }

.pagination-item path {
  fill: currentColor; }

.pagination-item.is-inactive {
  color: #e4e3e6;
  border: 2px solid currentColor;
  background: transparent; }

@media all and (min-width: 75em) {
  .pagination-item {
    position: fixed;
    top: 50%;
    margin-top: -.875rem; }

  .pagination-item.left {
    float: none;
    left: 2.25rem; }

  .pagination-item.right {
    float: none;
    right: 2.25rem; } }
@media all and (pointer: coarse) {
  .pagination-item {
    /* On touch-devices we increase prev/next buttons */
    width: 2.25rem;
    height: 2.25rem; } }
@font-face {
  font-family: 'open_sansbold';
  src: url("assets/fonts/opensans-bold-webfont.eot");
  src: url("assets/fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/opensans-bold-webfont.woff2") format("woff2"), url("assets/fonts/opensans-bold-webfont.woff") format("woff"), url("assets/fonts/opensans-bold-webfont.ttf") format("truetype"), url("assets/fonts/opensans-bold-webfont.svg#open_sansbold") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'open_sansbold_italic';
  src: url("assets/fonts/opensans-bolditalic-webfont.eot");
  src: url("assets/fonts/opensans-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/opensans-bolditalic-webfont.woff2") format("woff2"), url("assets/fonts/opensans-bolditalic-webfont.woff") format("woff"), url("assets/fonts/opensans-bolditalic-webfont.ttf") format("truetype"), url("assets/fonts/opensans-bolditalic-webfont.svg#open_sansbold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'open_sansitalic';
  src: url("assets/fonts/opensans-italic-webfont.eot");
  src: url("assets/fonts/opensans-italic-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/opensans-italic-webfont.woff2") format("woff2"), url("assets/fonts/opensans-italic-webfont.woff") format("woff"), url("assets/fonts/opensans-italic-webfont.ttf") format("truetype"), url("assets/fonts/opensans-italic-webfont.svg#open_sansitalic") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'open_sansregular';
  src: url("assets/fonts/opensans-regular-webfont.eot");
  src: url("assets/fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/opensans-regular-webfont.woff2") format("woff2"), url("assets/fonts/opensans-regular-webfont.woff") format("woff"), url("assets/fonts/opensans-regular-webfont.ttf") format("truetype"), url("assets/fonts/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'montserratbold';
  src: url("assets/fonts/montserrat-bold-webfont.eot");
  src: url("assets/fonts/montserrat-bold-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/montserrat-bold-webfont.woff2") format("woff2"), url("assets/fonts/montserrat-bold-webfont.woff") format("woff"), url("assets/fonts/montserrat-bold-webfont.ttf") format("truetype"), url("assets/fonts/montserrat-bold-webfont.svg#montserratbold") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'montserratregular';
  src: url("assets/fonts/montserrat-regular-webfont.eot");
  src: url("assets/fonts/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/montserrat-regular-webfont.woff2") format("woff2"), url("assets/fonts/montserrat-regular-webfont.woff") format("woff"), url("assets/fonts/montserrat-regular-webfont.ttf") format("truetype"), url("assets/fonts/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/*# sourceMappingURL=livid.css.map */
